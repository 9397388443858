<template>
  <div class="test-container">
    <div class="show">
      <img
        src="https://image.futechlab.com/images/2021/06/11/d04f4f7b-5cad-3e07-9964-c4782e813ad2.jpg"
        alt=""
        width="100%"
      />
    </div>
    <div class="cover" ref="cover"></div>
    <div class="control">
      <van-field name="radio" label="WhenDark">
        <template #input>
          <van-stepper v-model="whencover" integer />
        </template>
      </van-field>
      <van-field name="radio" label="DarkTime">
        <template #input>
          <van-stepper v-model="covertime" integer />
        </template>
      </van-field>
      <van-field v-model="count" label="TimeCount" readonly />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0,
      whencover: 100,
      covertime: 100,
    };
  },
  mounted() {
    let dark = false;
    let darkcount = 0;
    setInterval(() => {
      if (dark) {
        if (darkcount % this.covertime === 0) {
          dark = false;
          darkcount = 0;
        } else {
          darkcount++;
        }
      } else if (this.count % this.whencover === 0) {
        dark = true;
        darkcount++;
      }
      this.$refs.cover.style.display = dark ? "block" : "none";
      this.count++;
    }, 1);
  },
};
</script>

<style>
.test-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.cover,
.show {
  position: absolute;
  width: 100%;
  height: 100%;
}

.show {
  text-align: center;
  z-index: 1;
}

.cover {
  z-index: 99;
  background-color: #000000;
  display: block;
}

.control {
  z-index: 999;
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>